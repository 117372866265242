import { useEffect, useState, useRef, PropsWithChildren } from "react"

const TickerCell = (props: PropsWithChildren<{ index: number; pos: number }>) => {
  const [num, setNum] = useState(0) // Current number displayed
  const [phase, setPhase] = useState<"scramble" | "final">("scramble") // Scrambling or final count phase

  const scrambleOrderRef = useRef<number[]>([]) // Randomized order of numbers
  const scrambleIndexRef = useRef(0) // Current index in the scramble array

  // Utility function to generate a shuffled array of numbers 0-9
  const generateRandomOrder = (): number[] => {
    const numbers = Array.from({ length: 10 }, (_, i) => i)
    for (let i = numbers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[numbers[i], numbers[j]] = [numbers[j], numbers[i]] // Swap elements
    }
    return numbers
  }

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (phase === "scramble") {
      let scrambleCount = 0
      scrambleOrderRef.current = generateRandomOrder() // Initialize scramble order

      interval = setInterval(() => {
        // Update number from scramble order
        setNum(() => {
          const nextNum = scrambleOrderRef.current[scrambleIndexRef.current]
          scrambleIndexRef.current = (scrambleIndexRef.current + 1) % scrambleOrderRef.current.length

          return nextNum ?? 0
        })

        scrambleCount++
        if (scrambleCount > 20) {
          clearInterval(interval)
          setPhase("final")
        }

        // Regenerate a new random order periodically
        if (scrambleCount % scrambleOrderRef.current.length === 0) {
          scrambleOrderRef.current = generateRandomOrder()
        }
      }, 10)
    } else if (phase === "final") {
      let current = 0

      interval = setInterval(() => {
        setNum((prev) => {
          if (prev < props.pos) {
            current = prev + 1
            return current
          } else {
            clearInterval(interval)
            return props.pos
          }
        })
      }, 75)
    }

    return () => clearInterval(interval) // Cleanup interval on unmount or phase change
  }, [phase, props.pos])

  return <div>{num}</div>
}

export default TickerCell
