import { motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import TickerCell from "./TickerCell"

type Props = {
  value: string
  showing: boolean
  delay: number
}

export function Ticker({ value, showing, delay }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    const player = setTimeout(() => {
      if (showing) {
        setPlaying(true)
      } else setPlaying(false)

      return () => clearInterval(player)
    }, delay * 5)
  }, [showing])

  return (
    <div className={`inline-flex flex-row ${env.admin ? "sr-only" : ""}`} ref={ref}>
      {value.split("").map((char, i) => {
        if (char.match(/[0-9]/)) {
          return <TickerCell key={i} index={i} pos={Number(char)}></TickerCell>
        } else {
          return (
            <motion.div className={`overflow-hidden type-body-l ${char === "/" ? "mx-1" : "mx-0"}`} key={i}>
              {char}
            </motion.div>
          )
        }
      })}
    </div>
  )
}
