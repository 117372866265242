export const meta: BlockMeta = {
  editMode: "both",
  title: "Averages / Actuals",
  description: "",
  keywords: ["averages", "actuals"],
  // @ts-ignore
  icon: "admin-settings",
  cache: false,
  tags: ["root"],
}

import { Eyebrow } from "@components/atoms/Eyebrow"
import { GridLines } from "@components/atoms/GridLines"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { Ticker } from "@components/atoms/Ticker"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"
import { defineBlock, EditableText, useInlineEditableValue } from "eddev/blocks"
import { useState } from "react"

export default defineBlock("content/averages-actuals", (props) => {
  const [actual, setActual] = useState<boolean>(false)
  const { addRef } = useAdjustedHeight()

  const [answer1, setAnswer1] = useInlineEditableValue("answer_1", "Enter text...")
  const [answer2, setAnswer2] = useInlineEditableValue("answer_2", "Enter text...")

  return (
    <div ref={(el) => (props.gridlines ? el && addRef(el) : undefined)} className={`subtheme-${props.scheme} relative`}>
      {props.gridlines && <GridLines />}
      <div className="w-full h-full flex flex-col items-center justify-center bg-bg py-[80px]">
        <div className="grid-auto relative items-center pb-8 lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 md:col-span-6 md:col-start-4 lg:col-span-4 lg:col-start-5 flex flex-col justify-center items-center">
            {props?.useEyebrow && (
              <>
                <div className="hidden md:block">
                  <Eyebrow size="m" style="primary" icon={props?.eyebrowIcon ?? undefined}>
                    {props?.eyebrowText ?? ""}
                  </Eyebrow>
                </div>
                <div className="md:hidden">
                  <Eyebrow size="l" style="primary" icon={props?.eyebrowIcon ?? undefined}>
                    {props?.eyebrowText ?? ""}
                  </Eyebrow>
                </div>
              </>
            )}
            <EditableText
              className="type-title-l text-text text-center w-full pt-4 pb-8"
              as="h3"
              store="title"
              placeholder="Enter title..."
            />

            <div className="flex items-center w-full px-6 py-4 bg-text rounded-md mb-6 justify-between">
              <div className="flex items-center">
                {actual ? (
                  <>
                    <i className="rounded-full bg bg-textAlt w-[40px] h-[40px] relative overflow-hidden">
                      {props.actuals1Image && (
                        <ResponsiveImage {...props.actuals1Image} objectFit="cover" className="w-full h-full" />
                      )}
                    </i>
                    <EditableText
                      className="type-body-l text-bg text-left px-5"
                      as="h3"
                      store="button_1"
                      placeholder="Enter text..."
                    />
                  </>
                ) : (
                  <>
                    <i className="rounded-full bg bg-textAlt w-[40px] h-[40px]" />
                    <div className="type-body-l text-bg text-left px-5">Supplier 1</div>
                  </>
                )}
              </div>
              <div className="type-body-l text-bg text-left">
                {actual ? (
                  <>
                    <Ticker value={answer1} showing={actual} delay={1} />
                    <EditableText
                      className={`type-body-l text-bg text-right ${env.admin ? "" : "sr-only"}`}
                      store="answer_1"
                      placeholder="Enter text..."
                    />
                  </>
                ) : (
                  <>?</>
                )}
              </div>
            </div>
            <div className="flex items-center w-full px-6 py-4 bg-text rounded-md mb-6 justify-between">
              <div className="flex items-center">
                {actual ? (
                  <>
                    <i className="rounded-full bg bg-textAlt w-[40px] h-[40px] relative overflow-hidden">
                      {props.actuals2Image && (
                        <ResponsiveImage {...props.actuals2Image} objectFit="cover" className="w-full h-full" />
                      )}
                    </i>
                    <EditableText
                      className="type-body-l text-bg text-left px-5"
                      as="h3"
                      store="button_2"
                      placeholder="Enter text..."
                    />
                  </>
                ) : (
                  <>
                    <i className="rounded-full bg bg-textAlt w-[40px] h-[40px]" />
                    <div className="type-body-l text-bg text-left px-5">Supplier 2</div>
                  </>
                )}
              </div>
              <div className="type-body-l text-bg text-left">
                {actual ? (
                  <>
                    <Ticker value={answer2} showing={actual} delay={1} />
                    <EditableText
                      className={`type-body-l text-bg text-right ${env.admin ? "" : "sr-only"}`}
                      store="answer_2"
                      placeholder="Enter text..."
                    />
                  </>
                ) : (
                  <>?</>
                )}
              </div>
            </div>

            <div className="my-2 px-10 w-full">
              <div className="flex bg-text rounded-md overflow-hidden relative">
                <button
                  className="py-5 w-1/2 type-body-l text-bg relative z-10 text-center"
                  onClick={() => setActual(!actual)}
                >
                  Averages
                </button>
                <button
                  className="py-5 w-1/2 type-body-l text-bg relative z-10 text-center"
                  onClick={() => setActual(!actual)}
                >
                  Actuals
                </button>
                <div
                  className={`absolute z-0 bg-accent h-full w-1/2 rounded-md transition-all in-out-sumday duration-300 ${actual ? "left-1/2" : "left-0"}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
